<template>
  <div class="body_section">
    <profileHeader :showFilter="false" :showGoback="true" />
    <div class="body_content">
      <div class="main_content">
        <!-- <div class="form_subheader">
            <h5><router-link to="/fillings"><img src="@/assets/images/return.svg"></router-link>Upload Return</h5>
        </div> -->
        <div class="user_details_header" v-if="filingDetails">
          <!--<router-link  to="/fillings" class="backall"><b-icon icon="arrow-left" aria-hidden="true"></b-icon>Back</router-link>
             <figure><img src="@/assets/images/user.jpg"></figure> -->

          <figure>
            <img
              :src="checkProperty(filingDetails.clientDetails, 'logo')"
              @error="setAltImg($event)"
            />
          </figure>
          <div class="user_details">
            <label
              >{{ checkProperty(filingDetails.clientDetails, 'busName')
              }}<span>
                {{ checkProperty(filingDetails, 'stateDetails', 'name') }}
              </span></label
            >
            <ul>
              <li>
                <a
                  ><em><img src="@/assets/images/message1.svg" /></em
                  >{{
                    checkProperty(
                      filingDetails.clientDetails.busContact,
                      'email',
                    )
                  }}</a
                >
              </li>
              <li>
                <a
                  ><em><img src="@/assets/images/phone.svg" /></em
                  >{{
                    checkProperty(
                      filingDetails.clientDetails.busContact,
                      'phoneCode',
                    )
                  }}
                  {{
                    checkProperty(
                      filingDetails.clientDetails.busContact,
                      'phone',
                    )
                  }}</a
                >
              </li>
            </ul>
            <p>
              Filing Period :<span>{{
                checkProperty(filingDetails, 'filingStartDate') | formatDate
              }}</span
              ><em>to</em
              ><span>{{
                checkProperty(filingDetails, 'filingEndDate') | formatDate
              }}</span>
            </p>
          </div>
        </div>
        <div class="form_section add_note">
          <ValidationObserver ref="addNoteForm">
            <form v-on:submit.prevent>
              <!--    Payload: {
          "filingId":"",
          "documents":  [],
          "filedDate":"",
          "dueDate":"",
          "comment":"",
          "filedAmount":""


	},
         --->
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label class="form_label">Date of Filing</label>
                    <div
                      id="datefiling"
                      class="input-group date"
                      data-date-format="dd-mm-yyyy"
                    >
                      <ValidationProvider
                        name="Filing Date"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <datepicker
                          :disabledDates="disabledDates"
                          :maxDate="new Date()"
                          :max-date="new Date()"
                          :required="true"
                          format="yyyy-MM-dd"
                          v-model="Payload.filedDate"
                          name="uniquename"
                        ></datepicker>

                        <span class="input-group-addon"
                          ><i class="glyphicon glyphicon-calendar"></i
                        ></span>
                        <span class="v-messages error-msg">{{
                          errors[0]
                        }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label class="form_label">Due Date</label>
                            <div id="duedate" class="input-group date" data-date-format="dd-mm-yyyy">
                               <ValidationProvider name="Due Date " rules="required" v-slot="{ errors }">
                                <datepicker  :maxDate="new Date()" :max-date="new Date()" :required="true" format="yyyy-MM-dd" v-model="Payload.dueDate" name="uniquename"></datepicker>

                                <span class="input-group-addon"><i class="glyphicon glyphicon-calendar"></i></span>
                                <span class="v-messages error-msg">{{ errors[0] }}</span>
                            </ValidationProvider></div>
                        </div>
                    </div>
                </div>-->
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label class="form_label">Tax</label>
                    <input
                      style="background-color: #efefef !important"
                      disabled
                      :value="taxAmount"
                      type="number"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label class="form_label">Amount Paid</label>
                    <ValidationProvider
                      name="Amount"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <input
                        v-model="Payload.filedAmount"
                        type="number"
                        class="form-control"
                      />
                      <span class="v-messages error-msg">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label class="form_label">Difference Amount</label>
                    <ValidationProvider
                      name="Amount"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <input
                        :disabled="true"
                        v-model="adjustmentAmount"
                        type="number"
                        class="form-control"
                      />
                      <span class="v-messages error-msg">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label class="form_label">Remarks</label>
                    <ValidationProvider
                      name="Remarks"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <textarea
                        v-model="Payload.comment"
                        rows="4"
                        class="form-textarea"
                      ></textarea>
                      <span class="v-messages error-msg">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
              <span class="divider"></span>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label class="form_label">Reciept</label>
                    <div class="upload_sec relative">
                      <uploadFile
                        @onUploadComplete="onUploadComplete"
                        :allowMultiple="false"
                        :acceptedFiles="'*'"
                        :takeIndex="false"
                        :payLoadKey="'documents'"
                        :payLoadDocumentsKey="'documents'"
                      />
                      <!-- <div class="cnt_loader"><img src="@/assets/images/loader.gif"></div> -->
                    </div>
                    <ul class="uploaded-list">
                      <template v-for="(other, indx) in Payload.documents">
                        <li :key="indx">
                          {{ other.name }}
                          <span @click="removeItem(indx, Payload.documents)"
                            >X</span
                          >
                        </li>
                      </template>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-actions">
                    <!--<button class="secondary_btn">Cancel</button>-->
                    <button
                      :disabled="checkForm"
                      @click="addNote()"
                      class="primary_btn"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>

      <footerView ref="rout_view"></footerView>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
//import Multiselect from "vue-multiselect-inv";
import Datepicker from 'vuejs-datepicker';

import uploadFile from '@/components/uploadFile.vue';

import _ from 'lodash';

import profileHeader from '@/components/ProfileHeader.vue';
import footerView from '@/components/footer.vue';

export default {
  name: 'UploadReturns',
  provide() {
    return {
      parentValidator: this.$validator,
    };
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    Datepicker,
    //VuePhoneNumberInput,
    //Multiselect,
    //  VueAutosuggest,
    //FileUpload
    uploadFile,
    profileHeader,
    footerView,
  },
  methods: {
    changedGlobalclient(cl) {
      this.log(cl);
      //this.$refs.rout_view.serchTitle(cl);
    },

    getFilingDetails() {
      const payload = {
        filingId: this.Payload.filingId,
      };
      this.$store
        .dispatch('getFilingDetails', payload)
        .then((res) => {
          this.filingDetails = res;
          this.Payload['filedAmount'] = this.filingDetails['amount'];
          this.taxAmount = this.Payload.filedAmount;
        })
        .catch((error) => {
          this.$vs.notify({
            title: 'Error',
            text: error.message,
            position: 'top-right',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          });
        });
    },
    asyncFind(query) {
      this.tagToList = [];
      this.searchText = query;
      this.getCliList();
    },
    getMaterData(category) {
      let payLoad = {
        matcher: {
          searchString: '',
          //"cntryId": 101,
          //"stateId": 665,
          //"cntryIds": [38], // Requied for "state"
          //"stateIds": [665], // Requied for "city"
        },
        page: 1,
        perpage: 10000000000,
        category: category, // acc_method, bank, user_status, user_role, user_list, country, state, platform, business, bus_type, notes_category, notes_status, client_status, nexus, pay_frequency
      };
      this.$store
        .dispatch('getMaterData', payLoad)
        .then((res) => {
          let dt = res.list;
          //if(category =="llc_type"){
          _.forEach(dt, (obj, index) => {
            dt[index]['selected'] = false;
            this[category].push(dt[index]);
          });

          //  }else{
          //     this[category] = dt;
          //  }
        })
        .catch((err) => {
          this.log(err);
        });
    },
    onUploadComplete(data) {
      console.log(data);
      if (
        _.has(data, 'finalDocuments') &&
        _.has(data, 'payLoadKey') &&
        data['finalDocuments'].length > 0
      ) {
        _.forEach(data['finalDocuments'], (fl) => {
          this.Payload[data['payLoadKey']].push(fl);
        });
      }
    },
    removeItem(index, data) {
      data.splice(index, 1);
    },
    getCliList() {
      let postData = {
        getForNotes: true,
        getMasterData: true,
        filters: {
          title: this.searchText,
        },
        // getAssignList: true,
        // userId: this.userId,
        sorting: { path: 'name', order: -1 },
        page: 1,
        perpage: 100,
      };
      this.clientList = [];
      this.originalCliList = [];
      this.$store
        .dispatch('getAssignToList', postData)
        .then((response) => {
          this.originalCliList = response.list;
          var cliList = this.originalCliList.map((item) => item.name);
          if (cliList.length > 0) {
            this.clientList = [
              {
                data: cliList,
              },
            ];
          }
        })
        .catch((err) => {
          this.log(err);
        });
    },

    //filings/upload-return
    addNote() {
      const valid = this.$refs['addNoteForm'].validate();
      console.log(valid);

      if (valid) {
        this.$store
          .dispatch('uploadReturn', this.Payload)
          .then((res) => {
            this.notify({ message: res, title: 'Success', type: 'success' });
            this.Payload = {
              filingId: '',
              documents: [],
              filedDate: '',
              dueDate: '',
              comment: '',
              filedAmount: '',
            };
            this.$refs['addNoteForm'].reset();

            this.$router.push('/fillings');
          })
          .catch((err) => {
            this.notify({ message: err, title: 'Error', type: 'error' });
          });
      }
    },
    //upDateNote
    upDateNote() {
      const valid = this.$refs['addNoteForm'].validate();

      if (valid) {
        this.Payload.taggedTo = [];
        if (this.Payload.taggedToDetails.length > 0) {
          _.forEach(this.Payload.taggedToDetails, (item) => {
            this.Payload.taggedTo.push(item._id);
          });
        }
        this.Payload['filingId'] = this.$route.params['filingId'];

        this.$store
          .dispatch('upDateNote', this.Payload)
          .then((res) => {
            this.notify({
              message: res.data.result.message,
              title: 'Success',
              type: 'success',
            });
            this.Payload = {
              accessLevelId: 1,
              title: '',
              comments: [],
              document: [],
              description: '',
              taggedTo: [],
              taggedToDetails: [],
            };
            this.$refs['addNoteForm'].reset();
          })
          .catch((err) => {
            this.notify({ message: err, title: 'Error', type: 'error' });
          });
      }
    },
  },
  data: () => ({
    disabledDates: {
      from: new Date(),
    },
    filingDetails: null,
    taxAmount: null,
    pageTitle: 'Upload Returns',
    isEdit: false,
    note_access_level: [],
    options: [
      { item: 1, name: 'Internal' },
      { item: 2, name: 'Clients' },
      { item: 3, name: 'Custom' },
    ],

    clientList: [],
    userdata: null,
    documents: [],
    originalCliList: [],
    Payload: {
      filingId: '',
      documents: [],
      filedDate: new Date(),
      dueDate: '',
      comment: '',
      filedAmount: '',
    },

    searchText: null,
  }),
  mounted() {
    this.userdata = this.$store.getters['auth/getuser'];
    this.getMaterData('note_access_level');
    if (_.has(this.$route.params, 'filingId')) {
      this.Payload.filingId = this.$route.params['filingId'];
      this.pageTitle = 'Edit Note';
      this.isEdit = true;
      this.getFilingDetails();
    }
  },
  computed: {
    adjustmentAmount() {
      if (this.filingDetails) {
        return Math.round(
          this.Payload['filedAmount'] - this.filingDetails['amount'],
        );
      } else {
        return 0;
      }
    },
    //   Payload: {
    //   "filingId":"",
    //   "documents":  [],
    //   "filedDate":"",
    //   "dueDate":"",
    //   "comment":"",
    //   "filedAmount":""
    //   }

    checkForm() {
      //Payload.accessLevelId
      if (
        this.Payload.filingId == '' ||
        this.Payload.comment == '' ||
        this.Payload.filedAmount == '' ||
        this.Payload.documents.length <= 0
      ) {
        return true;
      } else {
        console.log(this.Payload);
        return false;
      }
    },
  },
  beforeCreate() {
    this.userdata = this.$store.getters['auth/getuser'];
    // this.userdata = localStorage.getItem('user');
  },
};
</script>
